/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import { parseApiErrorMessage } from "utils/api"
import {
  assignDistributionToEdgeProvisioningRequest,
  createEdgeProvisioningRequest,
  deleteEdgeProvisioningRequest,
  getEdgeProvisioningDistributionRequest,
  getEdgeProvisioningRequest,
  getEdgeProvisioningsRequest,
  getFilterQueryParams,
  updateEdgeProvisioningRequest,
  // updateEdgeProvisioningRequest
} from "utils/edgeProvisionings"
import { makeLib } from "utils/misc"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS,
} from "utils/notifications"
  
import { makeActions } from "./utiliducks"

// import { getFunctionTopicParams, getMessageContent } from 'utils/mqtt'

/* == ACTIONS === */
const actionList = [
  "setEdgeProvisioningAction",
  "setEdgeProvisioningDistributionAction",
  "setEdgeProvisioningsAction",
  "addEdgeProvisioningAction",
  "updateEdgeProvisioningAction",
  "deleteEdgeProvisioningAction",
  "setPagingAction"
]
const {
  setEdgeProvisioningAction,
  setEdgeProvisioningDistributionAction,
  setEdgeProvisioningsAction,
  addEdgeProvisioningAction,
  updateEdgeProvisioningAction,
  deleteEdgeProvisioningAction,
  setPagingAction
} = makeActions("edgeProvisionings", actionList)

/* === INITIAL STATE === */
const initialState = {
  edgeProvisionings: [],
  edgeProvisioningsLib: {},
  paging: { previous_cursor: "", next_cursor: "" },
  currentEdgeProvisioning: undefined
}

/* === Reducer === */
export default createReducer(initialState, {
  [setEdgeProvisioningsAction]: (state, { payload: { edgeProvisionings }}={}) => ({
    ...state,
    edgeProvisionings,
    edgeProvisioningsLib: makeLib({data: edgeProvisionings})
  }),
  [setEdgeProvisioningAction]: (state, { payload: { edgeProvisioning }}={}) => ({
    ...state,
    currentEdgeProvisioning: edgeProvisioning
  }),
  [setEdgeProvisioningDistributionAction]: (state, { payload: { edgeProvisioningDistribution }}={}) => ({
    ...state,
    currentEdgeProvisioning: {
      ...state.currentEdgeProvisioning,
      edgeProvisioningDistribution
    }
  }),
  [addEdgeProvisioningAction]: (state, { payload: { edgeProvisioning }}) => ({
    ...state,
    edgeProvisionings: [
      ...state.edgeProvisionings,
      edgeProvisioning
    ],
    edgeProvisioningsLib: {
      ...state.edgeProvisioningsLib,
      [edgeProvisioning.id]: edgeProvisioning
    }
  }),
  [updateEdgeProvisioningAction]: (state, { payload: { id, edgeProvisioningData } }) => ({
    ...state,
    edgeProvisionings: state.edgeProvisionings.map(edgeProvisioning => edgeProvisioning.id === id ? edgeProvisioningData : edgeProvisioning),
    edgeProvisioningsLib: {
      ...state.edgeProvisioningsLib,
      [id]: {
        ...state.edgeProvisioningsLib[id],
        ...edgeProvisioningData
      }
    }
  }),
  [deleteEdgeProvisioningAction]: (state, { payload: { ids }}) => {
    const edgeProvisionings = state.edgeProvisionings.filter(({ id }) => !ids.includes(id))
    return (
      {
        ...state,
        edgeProvisionings,
        edgeProvisioningsLib: makeLib({data: edgeProvisionings}),
        currentEdgeProvisioning: undefined
      })
  },
  [CLEAN_SPACE]: () => initialState,
  [setPagingAction]: (state, { payload: { paging } }) => ({
    ...state,
    paging
  })
})

/* === DISPATCHERS === */
export const getEdgeProvisionings = (params) => {
  return async (dispatch) => {
    try {
      const response = await getEdgeProvisioningsRequest(params)
      const { data=[], paging } = response
      if (!Array.isArray(data)) throw new Error(`Invalid data format: ${JSON.stringify(data)}`)
      dispatch(setEdgeProvisioningsAction({ edgeProvisionings: data }))
      if (paging) dispatch(setPaging({ paging }))
      return data
    }
    catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Edge Provisionings could not be retrieved",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
      return []
    }
  }
}

export const getEdgeProvisioning = (id) => {
  return async dispatch => {
    try {
      if (!id) {
        dispatch(setEdgeProvisioningAction({edgeProvisioning: undefined}))
        return
      }
      const edgeProvisioning = await getEdgeProvisioningRequest(id)
      dispatch(setEdgeProvisioningAction({edgeProvisioning}))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Edge Provisioning could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getEdgeProvisioningDistribution = (id) => {
  return async dispatch => {
    try {
      const data = await getEdgeProvisioningDistributionRequest(id)
      dispatch(setEdgeProvisioningDistributionAction({edgeProvisioningDistribution: data}))
      return data
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Edge Provisioning distribution could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getNextEdgeProvisionings = (params) => {
  return async (dispatch, getState) => {
    const {
      edgeProvisionings: {
        edgeProvisionings,
        paging: {
          next_cursor
        }={}
      }
    } = getState()
    const { data, paging } = await getEdgeProvisioningsRequest({ ...params, next_cursor })
    dispatch(setPaging({ paging }))
    dispatch(setEdgeProvisioningsAction({ edgeProvisionings: [ ...edgeProvisionings, ...data ]}))
  }
}

export const createEdgeProvisioning = (edgeProvisioningData) => {
  return async (dispatch) => {
    try {
      const { name, filter, distribution } = edgeProvisioningData

      const response = await createEdgeProvisioningRequest({name, filter})
      const distribResp = await assignDistributionToEdgeProvisioningRequest(response?.id, distribution)
      dispatch(addEdgeProvisioningAction({ edgeProvisioning: response }))
      dispatch(setEdgeProvisioningDistributionAction({edgeProvisioningDistribution: distribResp}))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Edge Provisioning created successfully.",
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000,
      })
      return response
      
    } catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Edge Provisioning could not be created",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const updateEdgeProvisioning = (id, edgeProvisioningData) => {
  return async (dispatch) => {
    try {
      const { name, filter, distribution } = edgeProvisioningData

      const response = await updateEdgeProvisioningRequest(id, {name, filter})
      await assignDistributionToEdgeProvisioningRequest(response?.id, distribution)
      dispatch(updateEdgeProvisioningAction({ id, edgeProvisioningData: response }))
      dispatch(setEdgeProvisioningDistributionAction({edgeProvisioningDistribution: distribution}))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Edge Provisioning updated successfully.",
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000,
      })
      return response
      
    } catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Edge Provisioning could not be updated",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const deleteEdgeProvisioning = (id) => { //this function will be used in the details panel just for one specific edgeProvisioning version
  return async (dispatch) => {
    try {
      await deleteEdgeProvisioningRequest(id)
      dispatch(deleteEdgeProvisioningAction({ ids: id }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Successful",
        subtext: "Edge Provisioning successfully deleted",
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })
    } catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Edge Provisioning could not be deleted",
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const deleteEdgeProvisionings = (edgeProvisionings=[]) => { //this function will be used in the main list
  edgeProvisionings = Array.isArray(edgeProvisionings) ? edgeProvisionings : [ edgeProvisionings ]
  return async (dispatch) => {
    try {
      const responses = edgeProvisionings.map(async d => {
        return deleteEdgeProvisioningRequest(d.id)
      })
      await Promise.all(responses)
      dispatch(deleteEdgeProvisioningAction({ ids: edgeProvisionings }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Successful",
        subtext: `Successfully deleted ${edgeProvisionings.length} Edge Provisioning${edgeProvisionings.length !== 1 ? "s" : ""}`,
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })
    } catch(error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${edgeProvisionings.length === 1 ? "Edge Provisioning" : "Some Edge Provisionings"} could not be deleted`,
        subtext: parseApiErrorMessage(error),
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const setPaging = ({ paging }) => {
  return dispatch => dispatch(setPagingAction({ paging }))
}

export const getQueryParams = (tableName, tableColumns) => {
  return (dispatch, getState) => {
    const {
      authentication: {
        userInfo: {
          username
        }
      }
    } = getState()

    return getFilterQueryParams(tableName, tableColumns, username)
  }
}