import { del, get, post, put } from "utils/api"

import {getTableFilters} from "./storage"
import { EQUAL_OPERATOR } from "./tableColumns"

export async function getEdgeProvisioningsRequest(params) {
  const response = await get("/edge-provisionings", params)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function getEdgeProvisioningRequest(id) {
  const response = await get(`/edge-provisionings/${id}`)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function getEdgeProvisioningDistributionRequest(id) {
  const response = await get(`/edge-provisionings/${id}/edge-distribution`)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}

export async function createEdgeProvisioningRequest(edgeProvisioningData) {
  const response = await post("/edge-provisionings", edgeProvisioningData)
  const { status, data } = response
  if (status !== 200) throw new Error(`Request failed with status ${response.status} - ${response.data}`)
  return data
}

export async function assignDistributionToEdgeProvisioningRequest(id, distributionData) {
  const response = await put(`/edge-provisionings/${id}/edge-distribution`, distributionData)
  const { status, data } = response
  if (status !== 200) throw new Error(`Request failed with status ${response.status} - ${response.data}`)
  return data
}

export async function updateEdgeProvisioningRequest(id, edgeProvisioningData) {
  const response = await put(`/edge-provisionings/${id}`, edgeProvisioningData)
  const { status, data } = response
  if (status === 200) return data
  throw new Error(`Request failed with status ${response.status} - ${response.data}`)
}


export async function deleteEdgeProvisioningRequest(id) {
  const response = await del(`/edge-provisionings/${id}`)
  return response
}

export const initialColumns = [
  {
    key: "name",
    label: "Name",
    showFilter: true,
    singleFilter: true,
    operators: [EQUAL_OPERATOR]
  },
  {
    key: "filter",
    label: "Fleet Identifier",
    formatLabel: (filter) => filter.fleet
  }
]

export const getFilterQueryParams = (tableName, columns, username) => {
  const columnFilters = getTableFilters(tableName, username) || {}
  return getEdgeProvisioningsColumnFilterQuery(columnFilters, columns)
}

export function getEdgeProvisioningsColumnFilterQuery(columnFilters={}) {
  let filters = {}
  for(let colKey in columnFilters) {
    const {[colKey]: colFilters=[]} = columnFilters
    //skip if column has no filters
    if (colFilters.length === 0) continue

    if (colKey === "name") {
      if (colFilters[0]?.value) {
        filters[colKey] = colFilters[0].value
      }
    }
  }
  return filters
}