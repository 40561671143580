/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import { ADD_ROLES_ACTION, REMOVE_ROLES_ACTION } from "constants/accessControl"
import { makeLib } from "utils/misc"
import {
  addMessage,
  GLOBAL_NOTIFICATIONS,
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS,
  MESSAGE_TYPE_WARNING,
} from "utils/notifications"
import { getPoliciesRequest } from "utils/policies"
import { createRoleRequest, deleteMultipleRoles, getRolesRequest } from "utils/roles"
import { createInvitationRequest, deleteInvitationRequest, deleteUserRequest, getReceivedInvitationsRequest, getSentInvitationsRequest, getUsersRequest, updateInvitationRequest, updateInvitationStatusRequest } from "utils/users"
import { isUlid } from "utils/validations"

import { deletePolicies } from "./policies"
import { addRoles, getRolePolicies } from "./roles"
import { makeActions } from "./utiliducks"

export const invitationStatuses = {
  ACCEPTED: "ACCEPTED",
  PENDING: "PENDING",
  REJECTED: "REJECTED"
}

/* == ACTIONS === */
const actionList = [
  "setUsersAction",
  "removeUsersAction",
  "addInvitationAction",
  "addUserRolesAction",
  "removeUserRolesAction",
  "setUserRoles",
  "setUserPoliciesAction",
  "setReceivedInvitations",
  "setSentInvitations",
  "updateInvitationAction",
  "updateReceivedInvitationAction",
  "removeInvitationsAction"
]

const {
  setUsersAction,
  removeUsersAction,
  addInvitationAction,
  addUserRolesAction,
  removeUserRolesAction,
  setUserRoles,
  setUserPoliciesAction,
  setReceivedInvitations,
  setSentInvitations,
  updateInvitationAction,
  updateReceivedInvitationAction,
  removeInvitationsAction
} = makeActions("users", actionList)

/* === INITIAL STATE === */
const initialState = {
  users: [],
  usersLib: {},
  sentInvitations: [],
  sentInvitationsLib: {},
  userRoles: {},
  userPolicies: {},
  receivedInvitations: []
}

/* === Reducer === */
export default createReducer(initialState, {
  [setUsersAction]: (state, { payload: { users } } = {}) => ({
    ...state,
    users,
    usersLib: makeLib({ data: users })
  }),
  [removeUsersAction]: (state, { payload: { userIds } }) => {
    const users = state.users.filter(user => !userIds.includes(user.id))
    return {
      ...state,
      users,
      usersLib: makeLib({ data: users })
    }
  },
  [addInvitationAction]: (state, { payload: { invitation } } = {}) => ({
    ...state,
    sentInvitations: [
      ...state.sentInvitations,
      invitation
    ],
    sentInvitationsLib: {
      ...state.sentInvitationsLib,
      [invitation.id]: invitation
    }
  }),
  [addUserRolesAction]: (state, { payload: { userId, roles } } = {}) => {
    const currentRoles = state.userRoles[userId] || []
    state.userRoles[userId] = [...currentRoles, ...roles]
  },
  [removeUserRolesAction]: (state, { payload: { userId, roles } } = {}) => {
    const newRoles = state.userRoles[userId].filter(r => !roles.includes(r.role))
    state.userRoles[userId] = newRoles
  },
  [setUserRoles]: (state, { payload: { userId, roles } } = {}) => ({
    ...state,
    userRoles: {
      ...state.userRoles,
      [userId]: roles
    }
  }),
  [setUserPoliciesAction]: (state, { payload: { userId, policies }}={}) => ({
    ...state,
    userPolicies: {
      ...state.userPolicies,
      [userId]: policies
    }
  }),
  [setReceivedInvitations]: (state, { payload: { invitations } } = {}) => ({
    ...state,
    receivedInvitations: invitations
  }),
  [setSentInvitations]: (state, { payload: { invitations } } = {}) => ({
    ...state,
    sentInvitations: invitations,
    sentInvitationsLib: makeLib({ data: invitations })
  }),
  [updateInvitationAction]: (state, { payload: { invitation } } = {}) => ({
    ...state,
    sentInvitations: state.sentInvitations.map(i => i.id === invitation.id ? invitation : i),
    sentInvitationsLib: {
      ...state.sentInvitations,
      [invitation.id]: invitation
    }
  }),
  [updateReceivedInvitationAction]: (state, { payload: { invitation } } = {}) => ({
    ...state,
    receivedInvitations: state.receivedInvitations.map(i => i.id === invitation.id ? invitation : i),
  }),
  [removeInvitationsAction]: (state, { payload: { ids } }) => {
    const sentInvitations = state.sentInvitations.filter(user => !ids.includes(user.id))
    return {
      ...state,
      sentInvitations,
      sentInvitationsLib: makeLib({ data: sentInvitations })
    }
  },
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const getUsers = () => {
  return async dispatch => {
    try {
      const users = await getUsersRequest()
      dispatch(setUsersAction({ users }))
      return users
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Users could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const deleteUsers = (userIds) => {
  return async dispatch => {
    try {
      // TODO: we might need to pace request as we are doing with Things
      const responses = userIds.map(async id => {
        return deleteUserRequest(id)
      })
      await Promise.all(responses)
      dispatch(removeUsersAction({ userIds }))

      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Successful",
        subtext: `Successfully deleted ${userIds.length} user${userIds.length !== 1 ? "s" : ""}`,
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })

    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${userIds.length === 1 ? "User" : "Some users"} could not be deleted`,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const createInvitation = data => {
  return async dispatch => {
    try {
      const invitation = await createInvitationRequest(data)
      dispatch(addInvitationAction({ invitation }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Invitation successfully sent",
        type: MESSAGE_TYPE_SUCCESS
      })
      return invitation.id
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Invitation could not be sent",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}

export const resendInvitation = user => {
  return async dispatch => {
    const { id, roles, to_email } = user

    // delete old inviation
    const deleted = await dispatch(deleteSentInvitations([id], false))

    // send a new one
    if (deleted) {
      const newId = await dispatch(createInvitation({ to_email, roles }))
      return newId
    }
    else {
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Invitation could not be sent",
        subtext: "Please, create the invitation again",
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const getUserRoles = userId => {
  return async dispatch => {
    try {
      // TODO: add pagination
      const { data: roles } = await getRolesRequest({ subject: userId, limit: 1000 })
      dispatch(setUserRoles({ userId, roles }))
      return roles
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Could not get list of user roles",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const updateUserRoles = (userId, newRoles) => {
  return async (dispatch, getState) => {
    const { users: { userRoles: { [userId]: userRoles } = {} } = {} } = getState()
    const userRolesArray = userRoles.map(r => r.role)

    // find added and removed roles
    const add = newRoles.filter(r => !userRolesArray.includes(r))
    const remove = userRolesArray.filter(r => !newRoles.includes(r))

    // call api to update roles
    let updatedRoles = [...userRoles]
    let addedRoles = []
    let haveRequestsFailed = false

    if (add.length) {
      addedRoles = await dispatch(addRoles(add.map(role => ({ role, subject: userId }))))
      updatedRoles = [...updatedRoles, ...addedRoles]
      if (addedRoles.length !== add.length) haveRequestsFailed = true
    }

    if (remove.length) {
      // only remove if add was successful
      if (!haveRequestsFailed) {
        const rolesToRemove = remove.map(role => userRoles.find(r => r.role === role).id)
        const { has_errors, results } = await deleteMultipleRoles(rolesToRemove)
        const successful = []
        results.forEach((r, index) => { if (!has_errors.includes(index)) successful.push(rolesToRemove[index])})
        updatedRoles = userRoles.filter(r => !successful.includes(r.id))
        if (has_errors.length) haveRequestsFailed = true
      }
    }

    if (!haveRequestsFailed) {
      addMessage({
        text: "User roles updated successfully",
        type: MESSAGE_TYPE_SUCCESS
      })
    }
    else {
      addMessage({
        text: "User roles could not be updated",
        type: MESSAGE_TYPE_ERROR
      })
    }
    dispatch(setUserRoles({ userId, roles: updatedRoles }))
  }
}

export const updateMultipleRoles = (roles, action, users) => {
  return async (dispatch, getState) => {
    const {
      users: {
        userRoles: userRolesLib,
        sentInvitationsLib
      } = {}
    } = getState()


    // different behavior for users and invitations
    const acceptedUsers = []
    const invitations = []
    users.forEach( id => isUlid(id) ? invitations.push(id) : acceptedUsers.push(id))

    let hasRequestErrors = false

    if (acceptedUsers.length) {
      if (action === ADD_ROLES_ACTION) {
        // build list of roles to add
        const userRoles = []
        acceptedUsers.forEach(id => roles.forEach(r => userRoles.push({ subject: id, role: r})))

        // API request
        const { has_errors, results } = await createRoleRequest(userRoles)

        if (has_errors.length) hasRequestErrors = true // raise flag for error notification

        // Update roles in redux
        const successful = results.filter((r, index) => !has_errors.includes(index)).map(r => r.response)
        successful.map(s => dispatch(addUserRolesAction({userId: s.subject, roles: [s]})))
      }
      else if (action === REMOVE_ROLES_ACTION) {
        // build list of roles to remove
        const userRoles = []
        acceptedUsers.forEach(u => roles.forEach(r => userRoles.push(userRolesLib[u].find(ur => ur.role === r))))

        // API request
        const { has_errors, results } = await deleteMultipleRoles(userRoles.map(ur => ur.id))

        if (has_errors.length) hasRequestErrors = true // raise flag for error notification

        // Update roles in redux
        const successful = []
        results.forEach((r, index) => {
          if (!has_errors.includes(index)) successful.push(userRoles[index])
        })
        successful.map(s => dispatch(removeUserRolesAction({userId: s.subject, roles: [s.role]})))
      }
    }

    if (invitations.length) {
      invitations.forEach(async id => {
        if (sentInvitationsLib[id]) {
          const { roles: invitationRoles, to_email } = sentInvitationsLib[id]
          const newRoles = action === ADD_ROLES_ACTION ?
            `${invitationRoles},${roles.join(",")}`
            : invitationRoles.split(",").filter(r => !roles.includes(r)).join(",")
          try {
            const invitation = await updateInvitationRequest(id, { to_email, roles: newRoles })
            dispatch(updateInvitationAction({ invitation }))
          }
          catch (error) { hasRequestErrors = true }
        }
      })
    }

    if (hasRequestErrors) {
      addMessage({
        type: MESSAGE_TYPE_WARNING,
        text: "Some roles could not be updated"
      })
    }
    else {
      addMessage({
        type: MESSAGE_TYPE_SUCCESS,
        text: "Roles updated successfully"
      })
    }

  }
}


export const getUserPolicies = (userId) => {
  return async (dispatch, getState) => {
    try {
      const { users: { userRoles } } = getState()

      //Get policies by user roles
      let rolePolicies = []
      for (const r of userRoles[userId]) {
        rolePolicies = [...rolePolicies, ...await dispatch(getRolePolicies(r.role))]
      }

      //Get policies with userId as subject
      const response = await getPoliciesRequest({subject: userId, limit: 1000 })
      const { data } = response
      const userPolicies = data? data : response // preserve backwards compatibility
      const policies = [...userPolicies, ...rolePolicies]

      // Note: pagination cannot be implemented here because we are mixing policies from different sources, so currently if
      // there are >1000 policies, they won't show up here. However this whole section is under review and it is likely to change

      dispatch(setUserPolicies(userId, policies))
      return policies
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Could not get list of user policies",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const deleteUserPolicies = (policyIds, userId) => {
  return async dispatch => {
    try {
      await dispatch(deletePolicies(policyIds))

      //Fetch new user policies
      return dispatch(getUserPolicies(userId))
    }
    catch(error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Could not delete user policies",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
    }
  }
}

export const setUserPolicies = (userId, policies) => {
  return dispatch => {
    dispatch(setUserPoliciesAction({userId, policies}))
  }
}

export const getSentInvitations = () => {
  return async dispatch => {
    try {
      const invitations = await getSentInvitationsRequest()
      dispatch(setSentInvitations({ invitations }))
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Sent Invitations could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}

export const deleteSentInvitations = (ids, notify=true) => {
  return async dispatch => {
    try {
      // TODO: we might need to pace request as we are doing with Things
      const responses = ids.map(async id => {
        return deleteInvitationRequest(id)
      })
      await Promise.all(responses)
      dispatch(removeInvitationsAction({ ids }))

      if(notify) addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Successful",
        subtext: `Successfully deleted ${ids.length} invitation${ids.length !== 1 ? "s" : ""}`,
        type: MESSAGE_TYPE_SUCCESS,
        timeout: 4000
      })
      return true
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      if(notify) addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: `${ids.length === 1 ? "Invitation" : "Some invitations"} could not be deleted`,
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}

export const getReceivedInvitations = () => {
  return async dispatch => {
    try {
      const invitations = await getReceivedInvitationsRequest()
      dispatch(setReceivedInvitations({ invitations }))
      return invitations
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Invitations could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      return []
    }
  }
}

export const updateInvitation = (id, data) => {
  return async dispatch => {
    try {
      const invitation = await updateInvitationRequest(id, data)
      dispatch(updateInvitationAction({ invitation }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Invitation updated successfully",
        type: MESSAGE_TYPE_SUCCESS
      })
      return invitation.id
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Invitation could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}

export const updateInvitationStatus = (id, data, textOk="Invitation updated successfully", subtext=null, type=MESSAGE_TYPE_SUCCESS) => {
  return async dispatch => {
    try {
      const invitation = await updateInvitationStatusRequest(id, data)
      dispatch(updateReceivedInvitationAction({ invitation }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: textOk,
        subtext: subtext,
        type: type
      })
      return invitation.id
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Invitation could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR
      })
      return false
    }
  }
}
/* === UTILS === */
