// modules imports
import { connectRouter } from "connected-react-router"
import { combineReducers } from "redux"
import { reducer as form } from "redux-form"

// old imports, to be replaced below
import { authentication } from "reducers/auth"
// reduxModules imports
import editSchema from "reduxModules/editSchema"

import admin from "./admin"
import apiClient from "./apiClient"
import apiClientBeta from "./apiClientBeta"
import apps from "./apps"
import auManagement from "./auManagement"
import categoriesBeta from "./categoriesBeta"
import columnFilters from "./columnFilters"
import distributions from "./distributions"
import ecpImages from "./ecpImages"
import edgeOrchestration from "./edgeOrchestration"
import edgeOrchestrationBeta from "./edgeOrchestrationBeta"
import edgeProvisionings from "./edgeProvisionings"
import hfds from "./highFrequencyData"
import labels from "./labels"
import layout from "./layout"
import modal from "./modal"
import modelsBeta from "./modelsBeta"
import mqttCredentials from "./mqttCredentials"
import mqttInspector from "./mqttInspector"
import objects from "./objects"
import policies from "./policies"
import roles from "./roles"
import rollouts from "./rollouts"
import serverlessFunctions from "./serverlessFunctions"
import spaces from "./spaces"
import tableColumns from "./tableColumns"
import things from "./things"
import triggers from "./triggers"
import users from "./users"
import variables from "./variables"

const rootReducer = history => combineReducers({
  // old imports
  authentication,

  // module reducers
  admin,
  apiClient,
  apiClientBeta,
  apps,
  auManagement,
  categoriesBeta,
  columnFilters,
  distributions,
  ecpImages,
  edgeOrchestration,
  edgeOrchestrationBeta,
  edgeProvisionings,
  editSchema,
  form,
  hfds,
  labels,
  layout,
  modal,
  modelsBeta,
  mqttCredentials,
  mqttInspector,
  objects,
  policies,
  roles,
  rollouts,
  serverlessFunctions,
  spaces,
  tableColumns,
  things,
  triggers,
  users,
  variables,
  router: connectRouter(history)
})

export default rootReducer
