/* === IMPORTS === */
import { createReducer } from "@reduxjs/toolkit"

import { CLEAN_SPACE } from "actions/auth"
import { navigate } from "actions/navigate"
import {
  APPS,
  CATEGORIES,
  CLUSTER_MANAGEMENT,
  EDGE_APPS,
  EDGE_PROVISIONINGS,
  EVERY_THING,
  FUNCTIONS,
  LABELS,
  MODELS,
  OBJECT_STORAGE,
  RESOURCES,
  ROLES,
  TRIGGERS,
  USERS,
  VARIABLES_STORAGE
} from "constants/routes"

import { makeActions } from "./utiliducks"


/* == ACTIONS === */
const actionList = [
  "collapseMainAction",
  "expandMainAction",
  "collapsePaneAction",
  "expandPaneAction",
  "collapseSecondPaneAction",
  "expandSecondPaneAction",
  "changeMiscAction"
]
const {
  collapseMainAction,
  expandMainAction,
  collapsePaneAction,
  expandPaneAction,
  collapseSecondPaneAction,
  expandSecondPaneAction,
  changeMiscAction
} = makeActions("layout", actionList)

/* NAMING CONVENTIONS
 * On any given page, there are liable to be 3 "panels" aka "panes" at the time of writing this
 *  - "MAIN" :       The first panel is the main part of the page.
 *                   This is often a table of data.
 *                   Here, this is referred to as "main".
 *
 *  - "PANE" :       The second panel appears on the right as a split pane when an element is selected
 *                   This often shows element "details".
 *                   Here, this is referred to as the "pane".
 *
 *  - "SECONDPANE" : This is the third panel that opens up on certain pages.
 *                   Often this is for editing something in detail or displaying more data.
 *                   Here, it is referred to as the "secondPane".
 *
 * Some considerations that went into using this naming convention:
 *  - UnitySplitPane's current use of `main` and `pane` as attributes
 *  - Our current use of `secondPane` in the url query
*/

/* === INITIAL STATE === */
const initialState = {
  collapsedState: {
    [CATEGORIES]: {
      main: false,
      pane: false
    },
    [MODELS]: {
      main: false,
      pane: false
    },
    [EVERY_THING]: {
      main: false,
      pane: false
    },
    [CLUSTER_MANAGEMENT]: {
      main: false,
      pane: false
    },
    [RESOURCES]: {
      main: false,
      pane: false
    },
    [EDGE_APPS]: {
      main: false,
      pane: false
    },
    [EDGE_PROVISIONINGS]: {
      main: false,
      pane: false
    },
    [FUNCTIONS]: {
      main: false,
      pane: false
    },
    [TRIGGERS]: {
      main: false,
      pane: false
    },
    [USERS]: {
      main: false,
      pane: false
    },
    [ROLES]: {
      main: false,
      pane: false
    },
    [APPS]: {
      main: false,
      pane: false
    },
    [LABELS]: {
      main: false,
      pane: false
    },
    [OBJECT_STORAGE]: {
      main: false,
      pane: false
    },
    [VARIABLES_STORAGE]: {
      main: false,
      pane: false,
      secondPane: false
    }
  },
  misc: {
    modelDbClustersDropdownPinned: true
  }
}

/* === Reducer === */
export default createReducer(initialState, {
  [collapseMainAction]: (state, { payload: { page } }) => ({
    ...state,
    collapsedState: {
      ...state.collapsedState,
      [page]: {
        ...state.collapsedState[page],
        main: true
      }
    }
  }),
  [expandMainAction]: (state, { payload: { page } }) => ({
    ...state,
    collapsedState: {
      ...state.collapsedState,
      [page]: {
        ...state.collapsedState[page],
        main: false
      }
    }
  }),
  [collapsePaneAction]: (state, { payload: { page } }) => ({
    ...state,
    collapsedState: {
      ...state.collapsedState,
      [page]: {
        ...state.collapsedState[page],
        pane: true
      }
    }
  }),
  [expandPaneAction]: (state, { payload: { page } }) => ({
    ...state,
    collapsedState: {
      ...state.collapsedState,
      [page]: {
        ...state.collapsedState[page],
        pane: false
      }
    }
  }),
  [collapseSecondPaneAction]: (state, { payload: { page } }) => ({
    ...state,
    collapsedState: {
      ...state.collapsedState,
      [page]: {
        ...state.collapsedState[page],
        secondPane: true
      }
    }
  }),
  [expandSecondPaneAction]: (state, { payload: { page } }) => ({
    ...state,
    collapsedState: {
      ...state.collapsedState,
      [page]: {
        ...state.collapsedState[page],
        secondPane: false
      }
    }
  }),
  [changeMiscAction]: (state, { payload }) => ({
    ...state,
    misc: {
      ...state.misc,
      ...payload
    }
  }),
  [CLEAN_SPACE]: () => initialState
})

/* === DISPATCHERS === */
export const collapseMain = (page) => dispatch => dispatch(collapseMainAction({ page }))

export const expandMain = (page) => dispatch => dispatch(expandMainAction({ page }))

export const toggleMainCollapsedState = (page, newCollapsedState) => (dispatch, getState) => {
  const {
    layout: {
      collapsedState: {
        [page]: {
          main: paneCollapsed
        }={}
      }
    }
  } = getState()

  if (newCollapsedState === false || !!paneCollapsed) dispatch(expandMainAction({ page }))
  if (!!newCollapsedState || paneCollapsed === false) dispatch(collapseMainAction({ page }))
}

export const collapsePane = (page) => dispatch => dispatch(collapsePaneAction({ page }))

export const expandPane = (page) => dispatch => dispatch(expandPaneAction({ page }))

export const togglePaneCollapsedState = (page, newCollapsedState) => (dispatch, getState) => {
  const {
    layout: {
      collapsedState: {
        [page]: {
          second: paneCollapsed
        }={}
      }
    }
  } = getState()

  if (newCollapsedState === false || !!paneCollapsed) dispatch(expandPaneAction({ page }))
  if (!!newCollapsedState || paneCollapsed === false) dispatch(collapsePaneAction({ page }))
}

export const collapseSecondPane = (page) => dispatch => dispatch(collapseSecondPaneAction({ page }))
export const expandSecondPane = (page) => dispatch => dispatch(expandSecondPaneAction({ page }))
export const toggleSecondPaneCollapsedState = (page, newCollapsedState) => (dispatch, getState) => {
  const paneCollapsed = getState().layout.collapsedState[page].secondPane
  if (newCollapsedState === false || !!paneCollapsed) dispatch(expandSecondPaneAction({ page }))
  if (!!newCollapsedState || paneCollapsed === false) dispatch(collapseSecondPaneAction({ page }))
}

/* Call this function to close the secondPane
 * @param page (string) - the page on which the pane is being opened (must be a page from constants/layout.js)
 * @param [preventNavigation] (boolean) - pass true here to prevent a navigation action from being called
*/
export const closePane = (page, preventNavigation=false) => (dispatch, getState) => {
  // TODO: Consider taking params for and doing navigation from here
  const {
    layout: {
      collapsedState: {
        [page]: {
          main: mainCollapsed
        }={}
      }
    },
    router: {
      location: {
        query: {
          details,
          secondPane,
          tab,
          ...restOfQuery
        }
      }
    },
  } = getState()

  if (!preventNavigation) {
    dispatch(navigate({
      to: page,
      query: restOfQuery
    }))
  }
  if (mainCollapsed) dispatch(expandMainAction({ page }))
}

/* Call this function to open the secondPane
 * @param options (object)
 * @param options.page (string) - the page on which the pane is being opened (must be a page from constants/layout.js)
 * @param [options.secondPane] (string) - the `secondPane` url query param to navigate to
 * @param [options.queryParams] (object) - any other query params to add (not necessary to include the original query)
 * @note: if secondPane is not provided, no navigation will take place
*/
export const openSecondPane = ({ page, secondPane, queryParams={} }) => (dispatch, getState) => {
  const {
    layout: {
      collapsedState: {
        [page]: {
          main: mainCollapsed
        }={}
      }
    },
    router: {
      location: {
        query: oldQuery={}
      }={}
    }
  } = getState()

  if (secondPane) {
    const query = {
      ...oldQuery,
      secondPane,
      ...queryParams
    }
    dispatch(navigate({
      to: page,
      query
    }))
  }
  if (mainCollapsed === false) dispatch(collapseMainAction({ page }))
}

/* Call this function to close the secondPane
 * @param page (string) - the page on which the pane is being opened (must be a page from constants/layout.js)
 * @param [preventNavigation] (boolean) - pass true here to prevent a navigation action from being called
*/
export const closeSecondPane = (page, preventNavigation=false) => (dispatch, getState) => {
  const {
    layout: {
      collapsedState: {
        [page]: {
          main: mainCollapsed
        }={}
      }
    },
    router: {
      location: {
        query: {
          secondPane,
          ...newQuery
        }={}
      }={}
    }
  } = getState()

  if (!preventNavigation) {
    dispatch(navigate({
      to: page,
      query: newQuery
    }))
  }
  if (mainCollapsed) dispatch(expandMainAction({ page }))
}

export const changeMiscLayout = (changes={}) => dispatch => dispatch(changeMiscAction(changes))

export const handleExpand = ({key, collapsed}, page) => dispatch => {
  if (!collapsed) {
    if (key === MAIN_PANE) dispatch(expandMainAction({page}))
    else dispatch(expandPaneAction({page}))
  }
}


/* === UTILS === */

export function getCollapsedPanes(state, page){
  const {
    layout: {
      collapsedState: {
        [page]: {
          main: mainCollapsed,
          pane: paneCollapsed
        }={}
      }={}
    }
  } = state
  let collapsedPanes = []
  if (mainCollapsed) collapsedPanes.push(MAIN_PANE)
  if (paneCollapsed) collapsedPanes.push(FIRST_PANE)
  return collapsedPanes
}

export function getPaneLabels(visiblePanes, paneLabels) {
  let labels = {}
  visiblePanes.forEach(pane => {
    let label = ""
    switch (pane) {
    case MAIN_PANE:
      label = paneLabels[MAIN_PANE]
      break
    case FIRST_PANE:
      label = paneLabels[FIRST_PANE]
      break
    default:
      return
    }
    labels[pane] = label
  })
  return labels
}

export const getVisiblePanes = (firstPaneCondition=true, secondPaneCondition=false) => {
  let visiblePanes = [MAIN_PANE]
  if(firstPaneCondition) {
    visiblePanes.push(FIRST_PANE)
    if (secondPaneCondition) visiblePanes.push(LAST_PANE)
  }
  return visiblePanes
}

// PANES
export const MAIN_PANE = "main-pane"
export const FIRST_PANE = "first-pane"
export const LAST_PANE = "last-pane"
