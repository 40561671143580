import { parseApiErrorMessage, post } from "utils/api"

import { addMessage, GLOBAL_NOTIFICATIONS, MESSAGE_TYPE_ERROR, MESSAGE_TYPE_SUCCESS } from "./notifications"


const displayCategoriesListSampleQuery = {
  "query": "FOR c IN categories FILTER c.name == @category_name FOR t IN things FILTER c._id IN t.categories RETURN t",
  "count": true,
  "batchSize": 3,
  "bindVars": {
    "category_name": "catOne",
  }
}

const displayAverageValueSampleQuery = {
  "query": "FOR c in categories FILTER c.name == @category_name FOR t IN things FILTER c._id IN t.categories FILTER HAS(t.status, @property_name) COLLECT AGGREGATE average = AVG(t.status.@property_name) RETURN average",
  "count": true,
  "bindVars": {
    "category_name": "catOne",
    "property_name": "temperature"
  }
}

const displayLinksSampleQuery = {
  "query": "WITH things FOR t, e IN 1..1 ANY @building_id links FILTER e.rel == @rel_type RETURN t",
  "count": true,
  "bindVars": {
    "building_id": "things/01GQM2N4AK6V5K06GQ92QEP464",
    "rel_type": "building"
  }
}

export const exampleOptions = [
  {id: "1", label: "1. List Things in a Category"},
  {id: "2", label: "2. Property average value of all the Things in a Category"},
  {id: "3", label: "3. Get Things related to another one in a hierarchical structure"},
]

export const queryTemplates = {
  "1": displayCategoriesListSampleQuery,
  "2": displayAverageValueSampleQuery,
  "3": displayLinksSampleQuery
}


export async function customQueryRequest(body, showNotification=true) {
  const endpoint = "/query/cursor"
  try {
    const response = await post(endpoint, body, {beta: true})
    const { data } = response
    showNotification && addMessage({
      target: GLOBAL_NOTIFICATIONS,
      text: "Query processed!",
      type: MESSAGE_TYPE_SUCCESS
    })
    return data
  } catch (error) {
    showNotification && addMessage({
      target: GLOBAL_NOTIFICATIONS,
      text: "Query could not be processed",
      subtext: parseApiErrorMessage(error),
      type: MESSAGE_TYPE_ERROR
    })
  }
}