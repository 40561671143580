import { UnityCodeEditor } from "@bit/smartworks.unity-react.unity-core-react"
import React, { Component } from "react"

import { overrideDirtyColor } from "./InputStyles"

class CodeEditor extends Component {
  render() {
    const {
      input,
      label,
      input: {
        name,
        onChange=()=>{},
        value,
        ...otherInputProps
      }={},
      mode="json5",
      meta: {
        pristine,
        error
      }={},
      trackDirty,
      readOnly=false,
      embedded=false,
      onFocus=()=>{},
      onBlur=()=>{},
      ...otherProps
    } = this.props

    const id = `${label}-code-editor`
    return (
      <div
        id={`${id}-wrapper`}
        style={overrideDirtyColor({ trackDirty, pristine, error, styles: styles.field })}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <UnityCodeEditor
          id={id}
          label={label}
          onChange={onChange}
          value={value}
          key={`editor-${name}`}
          mode={mode}
          dirty={!!trackDirty && !pristine}
          error={error}
          readOnly={readOnly}
          embedded={embedded}
          {...otherInputProps}
          {...otherProps}

        />
      </div>
    )
  }
}

export default CodeEditor

const styles = {
  field: {
    display: "flex",
    flex: 1,
    height: "100%",
    "--active-color": "var(--light-grey-text-color)",
    "--gutter-color": "var(--medium-grey-background-color)",
    "--fold-color": "var(--medium-grey-text-color)"
  }
}
