const isDevelopment = process.env.NODE_ENV === "development"

export const HOST_URL = isDevelopment? process.env.REACT_APP_HOST_URL : window?.__ENV__.HOST_URL
export const API_GATEWAY_URL = isDevelopment? process.env.REACT_APP_API_GATEWAY_URL : window?.__ENV__.API_GATEWAY_URL
export const API_GATEWAY_VERSION = isDevelopment? process.env.REACT_APP_API_GATEWAY_VERSION : window?.__ENV__.API_GATEWAY_VERSION
export const AUTH_HYDRA_CLIENT = isDevelopment? process.env.REACT_APP_AUTH_HYDRA_CLIENT : window?.__ENV__.AUTH_HYDRA_CLIENT
export const MQTT_HOST = isDevelopment? process.env.REACT_APP_MQTT_HOST : window?.__ENV__.MQTT_HOST
export const MQTT_PORT = isDevelopment? process.env.REACT_APP_MQTT_PORT : window?.__ENV__.MQTT_PORT
export const MQTT_PROTOCOL = isDevelopment? process.env.REACT_APP_MQTT_PROTOCOL : window?.__ENV__.MQTT_PROTOCOL
export const MQTT_REJECT_UNAUTHORIZED = (isDevelopment? process.env.REACT_APP_MQTT_REJECT_UNAUTHORIZED : window?.__ENV__.MQTT_REJECT_UNAUTHORIZED) === "true"
export const PANO_STREAMS_URL = isDevelopment? process.env.REACT_APP_PANO_STREAMS_URL : window?.__ENV__.PANO_STREAMS_URL
export const PANO_VIZ_URL = isDevelopment? process.env.REACT_APP_PANO_VIZ_URL : window?.__ENV__.PANO_VIZ_URL
export const PROXY_URL = isDevelopment? process.env.REACT_APP_PROXY_URL : window?.__ENV__.PROXY_URL
export const SEND_ANALYTICS = isDevelopment? process.env.REACT_APP_SEND_ANALYTICS : window?.__ENV__.SEND_ANALYTICS
export const SCALE_ZERO_ALLOW_CONFIGURE = (isDevelopment? process.env.REACT_APP_SCALE_ZERO_ALLOW_CONFIGURE : window?.__ENV__.SCALE_ZERO_ALLOW_CONFIGURE) === "true"
export const IDP_URL = isDevelopment ? process.env.REACT_APP_IDP_URL : window?.__ENV__.IDP_URL
export const OBJECT_LIMIT_SIZE = isDevelopment ? process.env.REACT_APP_OBJECT_LIMIT_SIZE : window?.__ENV__.OBJECT_LIMIT_SIZE
export const OBJECT_BODY_LIMIT = isDevelopment ? process.env.REACT_APP_OBJECT_BODY_LIMIT : window?.__ENV__.OBJECT_BODY_LIMIT

// feature flags
export const A1_ACCOUNT_LINKING_ENABLED = (isDevelopment? process.env.REACT_APP_A1_ACCOUNT_LINKING_ENABLED : window?.__ENV__.A1_ACCOUNT_LINKING_ENABLED) === "true"
export const BOUNDARIES_ENABLED = (isDevelopment? process.env.REACT_APP_BOUNDARIES_ENABLED : window?.__ENV__.BOUNDARIES_ENABLED) === "true"
export const FREE_TRIAL_EXPIRATION_ENABLED = (isDevelopment? process.env.REACT_APP_FREE_TRIAL_EXPIRATION_ENABLED : window?.__ENV__.FREE_TRIAL_EXPIRATION_ENABLED) === "true"
