import { createReducer } from "@reduxjs/toolkit"
import { isObject } from "lodash"

import { CLEAN_SPACE } from "actions/auth"
import { createBatches, makeLib, parseValue } from "utils/misc"
import { addMessage, GLOBAL_NOTIFICATIONS, MESSAGE_TYPE_ERROR, MESSAGE_TYPE_SUCCESS } from "utils/notifications"
import { createVariableRequest, deleteVariablesRequest, getVariableRequest, getVariablesRequest, updateVariableRequest } from "utils/variables"

import { makeActions } from "./utiliducks"


const actionList = [
  "addVariableAction",
  "removeVariableAction",
  "setPagingAction",
  "setVariablesAction",
  "updateVariableAction"
]

const {
  addVariableAction,
  removeVariableAction,
  setPagingAction,
  setVariablesAction,
  updateVariableAction
} = makeActions("variables", actionList)

const initialState = {
  variables: [],
  variablesLib: {},
  paging: { previous_cursor: "", next_cursor: "" }
}

const sortProperties = variable => {
  // Order MQTT credentials properties to show username first and then password.
  if (variable.type && variable.type.includes("_mqtt_")) {
    const { username, password, ...rest } = variable.schema.properties
    variable.schema.properties = {
      ...(username && { username }),
      ...(password && { password }),
      ...rest
    }
  }
}

export default createReducer(initialState, {
  [addVariableAction]: (state, { payload: { variable } }) => {
    sortProperties(variable)
    if (state.variablesLib[variable.name]) {
      state.variables = state.variables.map(v => v.name === variable.name ? variable : v)
    } else {
      state.variables.push(variable)
    }

    state.variablesLib[variable.name] = variable
  },
  [removeVariableAction]: (state, { payload: { names }}) => {
    state.variables = state.variables.filter(secret => !names.includes(secret.name))
    state.variablesLib = makeLib({ data: state.variables, key: "name" })
  },
  [setPagingAction]: (state, { payload: { paging } }) => {
    state.paging = paging
  },
  [setVariablesAction]: (state, { payload: { variables } }) => {
    variables.forEach(v => { sortProperties(v) })
    state.variables = variables
    state.variablesLib = makeLib({ data: variables, key: "name" })
  },
  [updateVariableAction]: (state, { payload: { secret, oldName } }) => {
    state.variables = state.variables.map(s => s.name === oldName ? secret : s)
    delete state.variablesLib[oldName]
    sortProperties(secret)
    state.variablesLib[secret.name] = secret
  },
  [CLEAN_SPACE]: () => initialState
})

export const getVariable = name => {
  return async dispatch => {
    try {
      const variable = await getVariableRequest(name)
      dispatch(addVariableAction({ variable }))

      return variable
    } catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Variable could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
    }
  }
}

export const getVariables = ({ next=false }={}) => {
  return async (dispatch, getState) => {
    try {
      const {
        variables: {
          variables,
          paging: {
            next_cursor
          }={}
        }
      } = getState()

      const shouldGetNext = next && next_cursor
      if(next && !shouldGetNext) return

      const params = shouldGetNext ? { next_cursor } : null
      const { data, paging } = await getVariablesRequest(params)
      const variablesList = shouldGetNext ? [ ...variables, ...data ] : data
      dispatch(setVariablesAction({ variables: variablesList }))
      dispatch(setPagingAction({ paging }))
      return variablesList
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Variables could not be retrieved",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
    }
  }
}

export const createVariable = (body) => {
  return async dispatch => {
    try {
      const variable = await createVariableRequest(body)
      dispatch(addVariableAction({ variable }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Variable was created successfully",
        type: MESSAGE_TYPE_SUCCESS,
      })
      return variable
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Variable could not be created",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
    }
  }
}

export const deleteVariables = names => {
  return async dispatch => {
    try {
      const idBatches = createBatches(names)
      for (let i = 0; i < idBatches.length; i++) {
        const secretNames = idBatches[i]
        await deleteVariablesRequest({"variable[]": secretNames})
      }
      dispatch(removeVariableAction({ names }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Complete",
        subtext: `${names.length === 1 ? "Variable was" : `${names.length} variables were`} deleted successfully`,
        type: MESSAGE_TYPE_SUCCESS
      })
      return true
    }
    catch (error) {
      console.error(error)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Delete Failed",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
      return false
    }
  }
}

export const updateVariable = (updatedSecret, oldSecret) => {
  return async dispatch => {
    try {
      const { name: oldName } = oldSecret
      const variable = await updateVariableRequest(oldName, updatedSecret)
      dispatch(updateVariableAction({ secret: variable, oldName }))
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Variable was updated successfully",
        type: MESSAGE_TYPE_SUCCESS,
      })
      return variable
    }
    catch (error) {
      console.error(`${error.name}: ${error.message}`)
      addMessage({
        target: GLOBAL_NOTIFICATIONS,
        text: "Variable could not be updated",
        subtext: error.message,
        type: MESSAGE_TYPE_ERROR,
      })
    }
  }
}

const NUMBER_KEY_INDICATOR = "/__num__key__/"

export const isNumberPropertyKey = (propertyKey = "") => propertyKey.startsWith(NUMBER_KEY_INDICATOR)

export const createNumberPropertyKey = (propertyKey = "") => {
  if (isNaN(propertyKey) || !propertyKey) {
    return propertyKey
  }

  return NUMBER_KEY_INDICATOR+propertyKey
}

export const parseNumberPropertyKey = (propertyKey = "") => {
  if(isNumberPropertyKey(propertyKey)) {
    return propertyKey.slice(NUMBER_KEY_INDICATOR.length)
  }

  return propertyKey
}

export const renameNumberPropertyKeys = (values, withNumberKey = false, previousKey) => {
  if (!isObject(values) || Array.isArray(values)){
    return
  }

  Object.entries(values).map(([key, val]) => {
    if (withNumberKey) {
      const newKey = createNumberPropertyKey(key)
      if (newKey !== key) {
        values[newKey] = val
        delete values[key]
      }
    
    } else if (isNumberPropertyKey(key)) {
      values[parseNumberPropertyKey(key)] = val
      delete values[key]
    }
        
    if (previousKey !== "properties" && key === "required") {
      values.required = values.required.map(k => withNumberKey ? createNumberPropertyKey(k) : parseNumberPropertyKey(k))
    }
    
    if (typeof val === "object") {
      renameNumberPropertyKeys(val, withNumberKey, key)
    }
  })

}

export const createParse = (schema) => (value) => {
  let parsedNewValue = parseValue(value, schema.type)

  if (Array.isArray(parsedNewValue)) {
    parsedNewValue = parsedNewValue.map(v => parseValue(v, schema?.items?.type))
  }

  return parsedNewValue
}

export const createFormat = (type) => (value) => {
  if (type === "boolean") {
    return value ? "true" : "false"
  }

  return value
}

export const getDefaultValue = schemaType => {
  let newValue

  switch (schemaType) {
  case "boolean":
    newValue = true
    break
  case "object":
    newValue = {}
    break
  case "string":
    newValue = ""
    break
  case "integer":
  case "number":
    newValue = 0
    break
  case "array":
    newValue = []
    break
  case "null":
    newValue = null
    break
  default:
    newValue = ""
    break
  }

  return newValue
}


export const variableSchemaTypesOptions = [
  "array",
  "boolean",
  "number",
  "integer",
  "object",
  "string"
]
